<template>
  <div class="permissionGroup">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Account Details </CBreadcrumbItem>
    </teleport>
    <CCard>
      <CCardBody v-loading="loading">
        <section v-if="!loading">
          <SenderEmailModal
            ref="senderEmailModal"
            v-model:info="info.account_sender_email"
          />
          <CloseAccountModal
            ref="closeAccountModal"
            v-model:is-send="info.close_account_request_sent"
            :name="info.primary_user"
          />
          <AddAdminModal
            ref="addAdminModal"
            v-model:administrators="info.admins"
          />
        </section>
        <CContainer style="max-width: 100%; min-height: 72vh">
          <CRow>
            <CAccordion :active-item-key="1">
              <AccordionItem mobile-label="Account Info" item-key="1">
                <CRow :xs="{ cols: 1 }" :lg="{ cols: 2 }">
                  <CCol>
                    <CRow class="mobile-hide-768">
                      <CCol>
                        <h4>Account Info</h4>
                      </CCol>
                    </CRow>
                    <CRow class="mb-4">
                      <CCol :xs="6" :sm="5" :lg="6" :xl="5" :xxl="4">
                        <span style="word-break: break-word"
                          >Account Number</span
                        >
                      </CCol>
                      <CCol
                        :xs="6"
                        :sm="7"
                        :lg="6"
                        :xl="7"
                        :xxl="8"
                        class="text-md-start text-end"
                      >
                        <span class="fw-bold">{{
                          info.account.number || 'N/A'
                        }}</span>
                      </CCol>
                    </CRow>
                    <CRow class="mb-4">
                      <CCol :xs="6" :sm="5" :lg="6" :xl="5" :xxl="4">
                        <span style="word-break: break-word"
                          >Creation Date</span
                        >
                      </CCol>
                      <CCol
                        :xs="6"
                        :sm="7"
                        :lg="6"
                        :xl="7"
                        :xxl="8"
                        class="text-md-start text-end"
                      >
                        <span class="fw-bold">{{
                          $BtoF({ value: info.account.created_at })
                        }}</span>
                      </CCol>
                    </CRow>
                    <CRow class="mb-4">
                      <CCol :xs="6" :sm="5" :lg="6" :xl="5" :xxl="4">
                        <span style="word-break: break-word"
                          >Expiration Date</span
                        >
                      </CCol>
                      <CCol
                        :xs="6"
                        :sm="7"
                        :lg="6"
                        :xl="7"
                        :xxl="8"
                        class="text-md-start text-end"
                      >
                        <span class="fw-bold; text-decoration-underline">{{
                          info.account.expiration_date
                            ? $BtoF({ value: info.account.expiration_date })
                            : 'N/A'
                        }}</span>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol>
                    <CRow class="d-none d-lg-flex">
                      <CCol>
                        <h3>&nbsp;</h3>
                      </CCol>
                    </CRow>

                    <CRow class="mb-4">
                      <CCol :xs="12" :lg="12" :xl="12" :xxl="5">
                        <span style="word-break: break-word"
                          >Account Administrator</span
                        >
                      </CCol>
                      <CCol :xs="12" :lg="12" :xl="12" :xxl="7">
                        <span
                          class="itemValue"
                          :class="{ 'mr-3': !adminsCount }"
                          >{{ adminsItem }}</span
                        >
                        <span
                          v-if="adminsCount"
                          class="btnChange mx-2"
                          style="text-decoration: unset"
                          >+{{ adminsCount }}</span
                        >
                        <span
                          v-if="canEdit"
                          class="btnChange"
                          @click="$refs.addAdminModal.modal = true"
                          >Change</span
                        >
                      </CCol>
                    </CRow>

                    <CRow class="mb-4">
                      <CCol :xs="12" :lg="12" :xl="12" :xxl="5">
                        <span style="word-break: break-word">Payment Plan</span>
                      </CCol>
                      <CCol :xs="12" :lg="12" :xl="12" :xxl="7">
                        <span class="itemValue">Annual</span>
                        <span v-if="canEdit" class="btnChange ml-3"
                          >Change</span
                        >
                      </CCol>
                    </CRow>

                    <CRow class="mb-4">
                      <CCol :xs="12" :lg="12" :xl="12" :xxl="5">
                        <span style="word-break: break-word">Account Plan</span>
                      </CCol>
                      <CCol :xs="12" :lg="12" :xl="12" :xxl="7">
                        <span class="itemValue">Enterprise</span>
                        <span v-if="canEdit" class="btnChange ml-3"
                          >Change</span
                        >
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </AccordionItem>
              <AccordionItem mobile-label="Account Sender Email" item-key="2">
                <CRow>
                  <CCol xs="12" lg="6">
                    <h4 class="mobile-hide-768">Account Sender Email</h4>
                    <p>
                      Customize the names end emails your customers receive from
                      your Contract Connected account
                    </p>

                    <CRow class="mb-4">
                      <CCol :xs="12" :md="6" :lg="12" :xl="6" :xxl="5">
                        <span style="word-break: break-word"
                          >Your CC Emails Sent From</span
                        >
                      </CCol>
                      <CCol :xs="12" :md="6" :lg="12" :xl="6" :xxl="7">
                        <span class="itemValue">
                          <span
                            v-if="canEdit"
                            class="btnChange"
                            @click="$refs.senderEmailModal.modal = true"
                            >Change</span
                          >
                        </span>
                      </CCol>
                    </CRow>
                    <CRow class="mb-4">
                      <CCol :xs="12" :md="6" :lg="12" :xl="6" :xxl="5">
                        <span style="word-break: break-word">From Name</span>
                      </CCol>
                      <CCol :xs="12" :md="6" :lg="12" :xl="6" :xxl="7">
                        <span class="itemValue">{{
                          info.account_sender_email.from_name || 'N/A'
                        }}</span>
                      </CCol>
                    </CRow>
                    <CRow class="mb-4">
                      <CCol :xs="12" :md="6" :lg="12" :xl="6" :xxl="5">
                        <span style="word-break: break-word">From Email</span>
                      </CCol>
                      <CCol :xs="12" :md="6" :lg="12" :xl="6" :xxl="7">
                        <span
                          class="itemValue"
                          style="text-decoration: underline"
                        >
                          {{ info.account_sender_email.from_email || 'N/A' }}
                        </span>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol xs="12" lg="6" class="d-none d-md-block">
                    <CloseAccountBlock
                      @showModal="$refs.closeAccountModal.modal = true"
                    />
                  </CCol>
                </CRow>
              </AccordionItem>
              <AccordionItem mobile-label="Close Account" item-key="3">
                <CCol xs="12" class="d-block d-md-none">
                  <CloseAccountBlock
                    @showModal="$refs.closeAccountModal.modal = true"
                  />
                </CCol>
              </AccordionItem>
            </CAccordion>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
