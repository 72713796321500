<template>
  <CModal
    backdrop="static"
    size="lg"
    class="addNewAdminDialog"
    :visible="modal"
    @close="handleClose"
    @show="handleShow"
  >
    <CModalHeader :close-button="false">
      <CModalTitle class="text-center w-100">Add New Administrator</CModalTitle>
    </CModalHeader>
    <CModalBody v-loading="loading" class="text-center">
      <ConfirmDialog ref="confirmDialog" />
      <CRow class="justify-content-between">
        <CCol xs="9" lg="10">
          <CMultiSelect
            :options="usersList"
            :multiple="false"
            options-style="text"
            placeholder="Select The User"
            @change="handleChange"
          />
          <CFormText v-if="showError" style="color: red">
            Please, select the user to add him, as administrator.
          </CFormText>
        </CCol>
        <CCol xs="3" lg="2">
          <CButton class="w-100" color="primary" @click="handleAdd">
            Add
          </CButton>
        </CCol>
      </CRow>

      <h5 class="my-4">Current Administrators</h5>
      <CRow :xs="{ cols: 2, gutter: 4 }" class="adminRow">
        <CCol
          v-for="(admin, key) in adminList"
          :key="key"
          :style="{ textAlign: key % 2 ? 'left' : 'right' }"
        >
          <span>{{ admin.full_name }}</span>
          <CButton
            v-if="adminList.length > 1"
            variant="ghost"
            class="p-0"
            @click="handleDelete(key)"
          >
            <CIcon class="pb-1" name="cilX" />
          </CButton>
        </CCol>
      </CRow>
    </CModalBody>
    <CModalFooter>
      <CRow :xs="{ cols: 2, gutter: 4 }" class="w-100">
        <CCol style="text-align: right">
          <CButton
            color="primary"
            variant="outline"
            class="w-75 w-md-50"
            @click="handleClose"
          >
            Cancel
          </CButton>
        </CCol>
        <CCol style="text-align: left">
          <CButton
            color="primary"
            :disabled="loading"
            class="w-75 w-md-50"
            @click="handleSave"
          >
            Save
          </CButton>
        </CCol>
      </CRow>
    </CModalFooter>
  </CModal>
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import { isEqualArrays } from '@/utils/helper'

export default {
  name: 'AddAdminModal',
  components: { ConfirmDialog },
  inject: ['toast'],
  props: {
    administrators: { type: Array, default: [] },
  },
  emits: ['update:administrators'],
  data() {
    return {
      modal: false,
      loading: false,
      users: [],
      selected: [],
      selectedOrigin: [],
      adminList: [],
      selectedId: null,
      showError: false,
    }
  },
  computed: {
    usersList() {
      return this.users
        .filter((item) => !this.selected.includes(item.id))
        .map((item) => ({ value: item.id, text: item.full_name }))
    },
  },
  methods: {
    handleShow() {
      this.adminList = JSON.parse(JSON.stringify(this.administrators))
      this.selected = this.administrators.map((el) => el.id)
      this.selectedOrigin = JSON.parse(JSON.stringify(this.selected))
      this.getUsers()
    },
    closeDialog() {
      this.users = this.selected = this.selectedOrigin = []
      this.selectedId = null
      this.modal = this.showError = false
    },
    async handleClose() {
      if (isEqualArrays(this.selected, this.selectedOrigin)) {
        this.closeDialog()
      } else {
        await this.$refs.confirmDialog
          .confirm({
            text: 'Cancel changes?',
            info: 'You have unsaved changes. Are you sure, you want to cancel them?',
            confirmText: 'Yes',
            cancelText: 'No',
            reverse: true,
            size: 'lg',
          })
          .then((res) => {
            if (res) this.closeDialog()
          })
      }
    },
    handleChange(val) {
      if (val.length) {
        this.selectedId = val[0]['value']
        this.showError = false
      }
    },
    handleAdd() {
      if (!this.selectedId) {
        this.showError = true
        return
      }
      this.selected.push(this.selectedId)
      this.adminList.push(this.users.find((el) => el.id === this.selectedId))
      this.selectedId = null
    },
    handleDelete(key) {
      this.selected = this.selected.filter(
        (el) => el !== this.adminList[key]['id'],
      )
      this.adminList = this.adminList.filter(
        (el) => el.id !== this.adminList[key]['id'],
      )
    },
    async getUsers() {
      this.loading = true
      await this.$http.companies
        .getCompanyUsers({ params: { search: '' } })
        .then((res) => (this.users = res.data.data))
        .catch(() => this.toast('error', 'Error load users'))
        .finally(() => (this.loading = false))
    },
    handleSave() {
      this.loading = true
      this.$http.setting
        .updateAdminsAccountInfo({ users: this.selected })
        .then(() => {
          this.toast('success', 'Changes was successfully saved')
          this.$emit('update:administrators', this.adminList)
          this.closeDialog()
        })
        .catch(({ response }) => this.toast('error', response.data.message))
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style>
.addNewAdminDialog .form-multi-select,
.addNewAdminDialog .modal-body {
  text-align: left !important;
}

.addNewAdminDialog .modal-content {
  padding: 1rem !important;
}

.addNewAdminDialog .adminRow {
  max-height: 50vh;
  overflow-x: auto;
}
</style>
