<template>
  <CRow :class="{'mb-4': spaced}" :style="{flexDirection: column ? 'column' : 'row'}">
    <CCol sm="12" :lg="column ? '12' : '6'" :xl="column ? '12' : colsObj.left">
      <slot v-if="$slots.title" name="title" />
      <span style="word-break: break-word" v-else>{{title}}</span>
    </CCol>
    <CCol sm="12" :lg="column ? '12' : '6'" :xl="column ? '12' : colsObj.right">
      <slot v-if="$slots.value" name="value" />
      <span v-else style="font-weight: bold">{{value}}</span>
    </CCol>
  </CRow>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    value: { type: String, default: '' },
    column: { type: Boolean, default: false },
    cols: { type: Number, default: 3 },
    spaced: { type: Boolean, default: true }
  },
  computed: {
    colsObj() {
      return {
        left: this.cols,
        right: 12 - this.cols,
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
