<template>
  <CModal backdrop="static" size="lg" class="closeAccountDialog" :visible="modal" @close="handleClose" @show="handleShow">
    <CModalHeader :close-button="false">
      <CModalTitle class="text-center w-100">Send confirmation email?</CModalTitle>
    </CModalHeader>
    <CModalBody v-loading="loading" class="text-center">
      <p>
        This action requires email confirmation by the company owner {{ name }}
        <br>
        Are you sure, you want to send the confirmation email?
      </p>
    </CModalBody>
    <CModalFooter>
      <CRow :xs="{ cols: 2, gutter: 4 }" class="w-100">
        <CCol style="text-align: right">
          <CButton color="primary" variant="outline" class="w-md-50 w-75" @click="handleClose">
            Close
          </CButton>
        </CCol>
        <CCol style="text-align: left">
          <CTooltip v-if="isSend" content="Confirmation email was already sent to the company owner" placement="top">
            <template #toggler="{ on }">
              <CCol class="w-md-50 w-75" v-on="on">
                <CButton color="primary" disabled class="w-md-100 w-75">Send</CButton>
              </CCol>
            </template>
          </CTooltip>
          <CButton v-else color="primary" :disabled="loading" class="w-md-50 w-75" @click="handleCloseAccount">
            Send
          </CButton>
        </CCol>
      </CRow>
    </CModalFooter>
  </CModal>
</template>

<script>
export default {
  name: 'CloseAccountModal',
  inject: ['toast'],
  props: {
    name: { type: String, default: '' },
    isSend: { type: Boolean, default: false },
  },
emits: ['update:isSend'],
  data() {
    return {
      modal: false,
      loading: false,
    }
  },
  mounted() {},
  methods: {
    handleShow() {},
    handleClose() {
      this.modal = false
    },
    handleCloseAccount() {
      this.loading = true
      this.$http.setting
        .closeAccountInfo()
        .then((res) => {
          this.$emit('update:isSend', true)
          this.toast('success', res.data.message)
          this.handleClose()
        })
        .catch(() => this.toast('error', 'Error!'))
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style>
.closeAccountDialog .modal-body {
  text-align: left !important;
}

.closeAccountDialog .modal-content {
  padding: 1rem !important;
}

</style>
