<template>
  <CModal backdrop="static" size="lg" class="inviteUserDialog" :visible="modal" @close="handleClose" @show="handleShow" >
    <CModalHeader></CModalHeader>
    <CModalBody v-loading="loading" class="text-center">
      <CRow :xs="{ cols: 1, gutter: 3 }">
        <h5>
          Change Account Sender Email
        </h5>
        <CCol>
          <CForm>
            <CFormInput
              v-model="form.from_name"
              label="From Name"
              placeholder="Enter From Name"
              :invalid="!!errors.from_name"
              @blur="validate('from_name')"
            />
          </CForm>
          <CFormText style="color: red">
            {{ errors.from_name }}
          </CFormText>
        </CCol>
<!--        <CCol>-->
<!--          <CForm>-->
<!--            <CFormInput-->
<!--              v-model="form.from_email"-->
<!--              placeholder="Enter From Email"-->
<!--              :invalid="!!errors.from_email"-->
<!--              @blur="validate('from_email')"-->
<!--            />-->
<!--          </CForm>-->
<!--          <CFormText style="color: red">-->
<!--            {{ errors.from_email }}-->
<!--          </CFormText>-->
<!--        </CCol>-->
      </CRow>
    </CModalBody>
    <CModalFooter>
      <CRow :xs="{ cols: 2, gutter: 4 }" class="w-100">
        <CCol style="text-align: right">
          <CButton color="primary" variant="outline" class="w-75" @click="handleClose">
            Close
          </CButton>
        </CCol>
        <CCol style="text-align: left">
          <CButton color="primary" :disabled="loading" class="w-75" @click="updateEmailSender">
            Send
          </CButton>
        </CCol>
      </CRow>
    </CModalFooter>
  </CModal>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { parseError } from '@/utils/api'

export default {
  name: 'SenderEmailModal',
  inject: ['toast'],
  props: {
    info: { type: Object, default: {} },
  },
  emits: ['update:info'],
  data() {
    return {
      loading: false,
      modal: false,
      form: {
        from_email: '',
        from_name: '',
      },
      validator: {},
      errors: {},
    }
  },
  mounted() {
    this.setRules()
  },
  methods: {
    validate(field) {
      this.errors[field] = this.validator.validate(field, this.form[field])
    },
    setRules() {
      this.validator = validator({
        from_name: [rules.required, rules.strMax(255)],
        from_email: [rules.required, rules.email, rules.strMax(255)],
      })
    },
    handleShow() {
      this.form = JSON.parse(JSON.stringify(this.info))
    },
    handleClose() {
      this.form = {
        from_email: '',
        from_name: '',
      }
      this.errors = {}
      this.modal = false
    },
    updateEmailSender() {
      const validationResult = this.validator.validateAll(this.form)
      if (!validationResult.hasErrors) {
        this.loading = true
        this.$http.setting
          .updateAccountInfoSender(this.form)
          .then(() => {
            this.$emit('update:info', this.form)
            this.toast('success', 'Changes were successfully saved')
            this.handleClose()
          })
          .catch((err) => {
            err = parseError(err)
            if (err.status === 422) this.errors = err.validationMessages
            else this.toast('error', err.message)
          })
          .finally(() => (this.loading = false))
      } else {
        this.errors = validationResult.validationErrors
      }
    },
  },
}
</script>

<style>
.inviteUserDialog .modal-body {
  text-align: left !important;
}

.inviteUserDialog .modal-content {
  padding: 1rem !important;
}
</style>
