<template>
  <CRow>
    <CCol>
      <h4>Close Account</h4>
      <p>You must be an account administrator of your organization to perform this action</p>
      <p>Closing your account will delete your organization account, all users, all projects, all tasks, all documents, etc., as well as all log files, audit trails, etc.Please contact support@contractsconnected.com if further assistance is required</p>
      <CButton color="primary" @click="$emit('showModal')">Close My Account</CButton>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'CloseAccountBlock',
  emits: ['showModal']
}
</script>

<style scoped>

</style>
