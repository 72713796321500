import FormOutput from '@/components/Page/FormOutput'
import SenderEmailModal from './components/SenderEmailModal'
import CloseAccountModal from './components/CloseAccountModal'
import CloseAccountBlock from './components/CloseAccountBlock'
import AddAdminModal from './components/AddAdminModal'
import AccordionItem from '@/components/Page/AccordionItem'
import permissions from '@/utils/constants/permissions'

export default {
  name: 'AccountDetails',
  inject: ['toast'],
  components: {
    FormOutput,
    SenderEmailModal,
    CloseAccountModal,
    AddAdminModal,
    AccordionItem,
    CloseAccountBlock,
  },
  data() {
    return {
      loading: false,
      mountedComponent: false,
      info: {
        account: {},
        account_sender_email: {},
        admins: [],
        close_account_request_send: false,
        primary_user: '',
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchData()
      this.mountedComponent = true
    })
  },
  computed: {
    adminsItem() {
      let admins = this.info.admins
      return admins.length > 0 ? admins[0]['full_name'] : ''
    },
    adminsCount() {
      let admins = this.info.admins
      return admins.length > 1 ? admins.length - 1 : 0
    },
    canEdit() {
      return this.authUser.can(permissions.ACCOUNT_CONFIGURATION_CREATE)
    },
  },
  methods: {
    async fetchData() {
      this.loading = true
      await this.$http.setting
        .getAccountInfo()
        .then(({ data }) => {
          this.info = data
        })
        .catch((error) => {
          this.toast('error', 'Error fetch data')
        })
        .finally(() => (this.loading = false))
    },
  },
}
